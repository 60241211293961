.search-bar-container {
  z-index: 1000;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  max-width: 500px;

  & > input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    background: white;
    border: 2px solid rgba(0,0,0,0.2);
  }
}

.pac-logo:after {
  display: none;
}
