body {
  padding: 0;
  margin: 0;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

.leaflet-container {
  height: 100%;
}



