.legend-items {
  list-style-type: none;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  border: 2px solid rgba(0,0,0,0.2);
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);

  li {
    i, span {
      display:inline-block;
      vertical-align: middle
    }

    i {
      height: 15px;
      width: 15px;
    }

    span {
      margin-left: 5px;
    }
  }
}
